import providerService from '../../../../services/provider/email'
import notisphereServices from '../../../../services/notisphere/email'
import { VueEditor } from 'vue2-editor'
import Vue from 'vue'
import tinymce from 'vue-tinymce-editor'
import store from '../../../../store'
Vue.component('tinymce', tinymce)
export default {
    components: {
        VueEditor
    },
    data() {
        return {
            item: {
                email_type: null,
                subject: null,
                content: null,
                name: null,
                parameters: null
            },
            orgType: store.state.userType,
            service: store.state.userType =='PROVIDER' ? providerService: notisphereServices,
            bodyContent: null,
            theReplace: null,
            tinyOptions: {
                'height': 280,
                branding: false,
                statusbar: false,
                nonbreaking_force_tab: true,
                indentation: '30pt',
                content_style: 'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
                setup: function(ed) {
                    ed.on('keydown', function(evt) {
                        if (evt.keyCode == 9){
                            ed.execCommand('mceInsertContent', false, '&emsp;&emsp;&emsp;')
                            evt.preventDefault()
                        }
                    })
                }
            },
        }
    },
    created() {
        if (this.$route.params && this.$route.params.id) {
            this.getEmail(this.$route.params.id)
        }
    },
    methods: {
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid) {
                this.updateEmail()
            }
        },
        async cancel() {
            if (this.orgType == 'PROVIDER') {
                this.$router.push({ name: 'prov_EmailTemplate'})
            }
            else
            {
                this.$router.push({ name: 'noti_EmailTemplate' })
            }
        },
        updateEmail() {
            if (this.orgType == 'PROVIDER') {
                this.removeUnnecessaryTags()
                this.bodyContent = this.bodyContent.replace(this.bodyContent.substr(this.bodyContent.indexOf('<p>Sincerely,')), this.bodyContent.substr(this.bodyContent.indexOf('<p>Sincerely,')).replaceAll('</p>\n<p>', '<br />'))
            }
            this.service.updateEmail({
                ...this.item,
                content: this.item.content.replace(this.theReplace, this.bodyContent)
            }).then(resp => {
                if (!resp.error) {
                    this.$emit('onCompleted', 'UPDATE')
                    this._showToast('Updated successfully!')
                    this.cancel()
                }
            })
        },
        getEmail(type) {
            this.service.getEmail(type).then(resp=>{
                if(!resp.error){
                    this.item = resp.data.d
                    if (this.orgType == 'PROVIDER') {
                        this.item.content=  this.item.content.replace( this.item.content.substr( this.item.content.indexOf('<p>Sincerely,')), this.item.content.substr( this.item.content.indexOf('<p>Sincerely,')).replaceAll('</p>\n<p>','<br />'))
                    }
                    this.bodyContent = /<body.*?>([\s\S]*)<\/body>/.exec(this.item.content)[1]
                    this.theReplace = /<body.*?>([\s\S]*)<\/body>/.exec(this.item.content)[1]
                }
            })
        },
        removeUnnecessaryTags() {
            var data = this.bodyContent
            var replaceableList = []
            var getParameterName = (str) => {
                let list = this.item.parameters.filter(x => str.includes(x.value))
                return list.sort((a, b) => b.value.length - a.value.length)[0]
            }
            var position = 0
            while(data.includes('${', position)) {
                let openBracketIndex = data.indexOf('${', position)
                let closeBracketIndex = data.indexOf('}', openBracketIndex)
                position = closeBracketIndex - 1
                var str1 = data.substring(openBracketIndex, closeBracketIndex + 1)
                var param = getParameterName(str1)
                replaceableList.push({
                    beforeValue: str1,
                    afterValue: param.value
                })
            }
            replaceableList.forEach(x => {
                if(x.afterValue != null && x.afterValue != undefined){
                    data = data.replaceAll(x.beforeValue, '${' + x.afterValue + '}')
                }
            })
            this.bodyContent = data
        },
    }
}